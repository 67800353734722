import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Index";
import UserPayment from "./UserPayment";
import { useOutletContext } from "react-router-dom";
import { useAuthContext } from "../context/authContext";
import { useTranslation } from 'react-i18next';
import { getPaymentData } from "../api/payment";
import ExportButton from "../utils/ExportToExcel";
import { formatDate } from "../utils/formatDate";
import { formatDateWithoutTime } from "../utils/formatDateWithoutTime";
import { formatNumber } from "../utils/formatNumber";

function Payment() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [sidebarToggle] = useOutletContext();
  const { getCurrentUserId } = useAuthContext();
  const [activeTab, setActiveTab] = useState('tab1');
  const [searchQuery, setSearchQuery] = useState("");
  const currentUserId = getCurrentUserId();

  const [dataCache, setDataCache] = useState({
    tab1: null,
    tab2: null,
    tab3: null,
  });

  const [data, setData] = useState({ deals: [], dealActor: [] });

  const tabToPaymentStatus = {
    tab1: 'PAID',
    tab2: 'NOT PAID',
    tab3: 'PAYABLE'
  };

  const getApiData = async (userId, tab) => {
    setLoading(true);
    try {
      const paymentStatus = tabToPaymentStatus[tab];
      const response = await getPaymentData(userId, paymentStatus);
      setDataCache(prevCache => ({
        ...prevCache,
        [tab]: response
      }));
      setData(response);
    } catch (error) {
      console.error('Error fetching payment data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!currentUserId) return;
    if (!dataCache[activeTab]) {
      getApiData(currentUserId, activeTab);
    } else {
      setData(dataCache[activeTab]);
      setLoading(false);
    }
  }, [currentUserId, activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (!dataCache[tab]) {
      getApiData(currentUserId, tab);
    } else {
      setData(dataCache[tab]);
    }
  };

  const dealFinder = (details) => {
    const dealId = details.row['Deal ID']
    const deals = details.data.deals
    const deal = deals.find(d => dealId === d['Row ID'])
    return deal;
  }

  const dataHeader = [
    {
      key: 'deals',
      label: t("paymentDataHeader.deals"),
      valueFinder: (details) => {
        const deal = dealFinder(details)
        return deal.Description;
      },
    },
    {
      key: 'value',
      label: t("paymentDataHeader.value"),
      valueFinder: (details) => {
        const deal = dealFinder(details)
        return deal.Value;
      },
      translator: formatNumber
    },
    {
      key: 'startDate',
      label: t("paymentDataHeader.startDate"),
      valueFinder: (details) => {
        const deal = dealFinder(details)
        return deal['Start date'];
      },
      translator: formatDateWithoutTime
    },
    {
      key: 'paymentDate',
      label: t("paymentDataHeader.paymentDate"),
      exportKey: "Payment Date",
      translator: formatDate

    },
    {
      key: 'rate',
      label: t("paymentDataHeader.rate"),
      exportKey: "Rate"
    },
    {
      key: 'commission',
      label: t("paymentDataHeader.commission"),
      exportKey: "Commission",
      translator: formatNumber
    }
  ];

  const countDeals = (tab) => {
    const cachedData = dataCache[tab];
    if (!cachedData) return 0;
    return cachedData.dealActor.length;
  };

  return (
    <>
      <main className="h-full mt-11">
        <Navbar toggle={sidebarToggle} />

        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <div className="mb-3 flex flex-col sm:flex-row justify-between sm:items-center gap-2">
              <p className="font-bold text-xl text-black-700 px-6 bg-white-200 rounded-md">
                {t("paymentPage.header")}
              </p>

              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t("paymentPage.searchPlaceholder")}
                className="px-3 py-2 border rounded-md w-full sm:max-w-[15rem]"
              />
            </div>
            <div className="tabs">
              <ul className="nav flex gap-2 mb-3 tabs-scroll">
                <li
                  className={`h-10 px-4 py-2 rounded-md cursor-pointer whitespace-nowrap flex items-center justify-start gap-2 ${activeTab === 'tab1' ? 'bg-emerald-200' : 'bg-gray-200'}`}
                  onClick={() => handleTabClick('tab1')}
                >
                  <span className="mr-2">{t("paymentPage.tab1")}</span>
                  {activeTab === 'tab1' && <span>({countDeals('tab1')})</span>}
                </li>
                <li
                  className={`h-10 px-4 py-2 rounded-md cursor-pointer whitespace-nowrap flex items-center justify-start gap-2 ${activeTab === 'tab2' ? 'bg-emerald-200' : 'bg-gray-200'}`}
                  onClick={() => handleTabClick('tab2')}
                >
                  <span className="mr-2">{t("paymentPage.tab2")}</span>
                  {activeTab === 'tab2' && <span>({countDeals('tab2')})</span>}
                </li>
                <li
                  className={`h-10 px-2 py-2 rounded-md cursor-pointer whitespace-nowrap flex items-center justify-start gap-2 ${activeTab === 'tab3' ? 'bg-emerald-200' : 'bg-gray-200'}`}
                  onClick={() => handleTabClick('tab3')}
                >
                  <span className="mr-2">{t("paymentPage.tab3")}</span>
                  {activeTab === 'tab3' && <span>({countDeals('tab3')})</span>}
                </li>

                <ExportButton table={dataHeader} data={data} entityName="Pagesat" arrMapperKey={"dealActor"} className="sm:hidden block ml-2" />
                <ExportButton table={dataHeader} data={data} entityName="Pagesat" arrMapperKey={"dealActor"} className="sm:block hidden ml-auto" />
              </ul>
              <div className="outlet">
                <UserPayment
                  loading={loading}
                  dataHeader={dataHeader}
                  data={data}
                  activeTab={activeTab}
                  searchQuery={searchQuery}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Payment;

