import {
  faSignOut,
  faTable,
  faListUl,
  faUser,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./sidebar.css";
import SidebarLogo from "./SidebarLogo.jsx";
// import SidebarSearch from "./SidebarSearch.jsx";
import MenuList from "./MenuList.jsx";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/authContext.js";
import { useTranslation } from 'react-i18next';
import logoImage from "./logoImage.png";
import { auth } from "../../pages/auth/Firebase";

function Sidebar({ ...props }) {

  const { t } = useTranslation();
  const initMenus = [
    // {
    //   label: t('menusPage.header'), 
    // }, 
    {
      label: t('menusDataHeader.deals'),
      path: '/deals',
      icon: faTable,
    },
    {
      label: t('menusDataHeader.payments'),
      path: '/payment',
      icon: faMoneyCheckDollar,
    },
    {
      label: t('menusDataHeader.customers'),
      path: '/customers',
      icon: faListUl,
    },
  ];
  const navigate = useNavigate();
  const [menus] = useState(initMenus);
  // const [scButton, setScButton] = useState(false);
  // const search = useRef("");
  const { logout } = useAuthContext();

  // const handleChange = (e) => {
  //   if (e.target.value) {
  //     setScButton(true);
  //     setMenus(
  //       menus.filter((el) => {
  //         return el.label.toLowerCase().includes(e.target.value.toLowerCase());
  //       })
  //     );
  //   } else {
  //     setScButton(false);
  //     setMenus(initMenus);
  //   }
  // };

  // const clearSearch = () => {
  //   search.current.value = "";
  //   setMenus(initMenus);
  //   setScButton(false);
  // };

  const email = auth.currentUser?.email.split('@')[0];

  return (
    <>
      <aside
        id="sidebar"
        className={`sidebarWrapper md:translate-x-0 -translate-x-full md:z-0 z-50 no-scrollbar ${props.className}`}
      >
        {/* Sidebar wrapper */}
        <div className="md:w-64 border-r-2 border-gray-100 h-full flex-col flex flex-shrink-0">
          {/* Logo */}
          <SidebarLogo toggle={props.toggle} imageUrl={logoImage} />


          {/* Search Menu */}
          {/* <SidebarSearch
            clearSearch={clearSearch}
            handleChange={handleChange}
            scButton={scButton}
            search={search}
          /> */}

          {/* Menu */}
          <MenuList menus={menus} toggle={props.toggle} t={t} />


          {/* Profile */}
          <div className="pt-2 border-t border-gray-300 sm:mb-20 md:mb-0">
            <div className="py-2 px-4">

              <div className="flex justify-center items-center gap-4 mb-4">
                <FontAwesomeIcon icon={faUser} className="fa-lg text-emerald-600" />
                <span className="text-gray-500">{email}</span>

              </div>
              {/* Logout Button */}
              <button
                className="py-2 px-4 border border-emerald-500 bg-emerald-600 w-full rounded-full text-gray-200 hover:bg-emerald-600 hover:border-emerald-600 justify-end text-sm"
                onClick={() => {
                  logout()
                  navigate("/auth/login")
                }
                }
              >
                <FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon> {t("logoutPage.header")}
              </button>
            </div>
          </div>
        </div>
      </aside>

      {props.className === "mobile" && (
        <div
          id="overlaySidebar"
          onClick={props.toggle}
          className="hidden absolute w-full h-screen bg-black z-10 inset-0 opacity-60"
        >
          <div></div>
        </div>
      )}
    </>
  );
}

export default Sidebar;