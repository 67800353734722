import React from "react";
import Navbar from "../components/Navbar/Index";
import { useLocation, useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import UserItems from "./UserItems";
import { getItemsTableData, getItemsTableDeals } from "../api/itemsTable";

function ItemsTable() {
  const [sidebarToggle] = useOutletContext();
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState()

  useEffect(() => {
    if (!pathname) return
    setId(pathname.split('/')[1])
  }, [pathname])
  //fetch
  const defaultValue = []
  const [data, setData] = useState(defaultValue);

  const getApiData = async (id) => {
    setLoading(true);
    try {
      const response = await getItemsTableDeals(id);
      setData(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!id) return
    getApiData(id);
  }, [id]);

  const [dealTitle, setDealTitle] = useState(defaultValue);

  const getApiDealTitle = async (id) => {
    try {
      const response = await getItemsTableData(id);
      setDealTitle(response);
    } catch (error) {
      console.error('Error fetching deal title:', error);
    }
  };
  useEffect(() => {
    if (!id) return
    getApiDealTitle(id);
  }, [id]);

  const dataHeader = [
    {
      key: "name",
      label: t("itemsDataHeader.name"),
    },
    {
      key: "qty",
      label: t("itemsDataHeader.qty"),
    },
    {
      key: "value",
      label: t("itemsDataHeader.value"),
    },
    {
      key: "notes",
      label: t("itemsDataHeader.notes"),
    },
  ];

  return (
    <>
      <main className="h-full mt-11">
        <Navbar toggle={sidebarToggle} />

        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <p className="font-bold text-xl text-black-700 py-4 px-6 bg-white-200 rounded-md">
              {dealTitle} -

              {loading ? null : !data
                ? t("itemsPage.noItems")
                : t("itemsPage.header")}
            </p>
            {data && data.length > 0 && (

              <UserItems loading={loading} dataHeader={dataHeader} data={data} />
            )}
          </div>
        </div>
      </main>
    </>
  );

}
export default ItemsTable