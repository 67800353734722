// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxyPkWya6p8O3624B8Y-9f8u43-9-S0MQ",
  authDomain: "react-firebase-56b0c.firebaseapp.com",
  projectId: "react-firebase-56b0c",
  storageBucket: "react-firebase-56b0c.appspot.com",
  messagingSenderId: "863638404325",
  appId: "1:863638404325:web:7a9f319ea10d48cb63280c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Export the 'auth' object
export const auth = getAuth(app);
export default app;
