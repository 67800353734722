
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

function EmptyTable() {
  const { t } = useTranslation();
  return (
    <div>
      <div>  <FontAwesomeIcon className="icon" style={{ fontSize: '2.5rem', marginTop: '1.5rem', marginRight: '6rem' }} icon={faList} /> </div>
      <div className="mobile-margin" style={{ marginRight: '6rem' }}>
        {t('emptyTable.noData')}
      </div>
    </div>
  );
}

export default EmptyTable;
