import { faBars, faGlobe, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import i18n from '../../i18n';


function Index({ toggle }) {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {

    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <header className="fixed md:right-4 top-0 w-full">
        <div className="shadow-sm">
          <div className="relative bg-white flex w-full items-center px-6 py-2.5">
            <div className="flex-1">
              <p className="block md:hidden cursor-pointer">
                <FontAwesomeIcon icon={faBars} onClick={toggle} />
              </p>
            </div>
            <div className="relative">
              <ul className="flex flex-row gap-4 items-center mr-1">
                <li className="relative">
                  <span
                    className="cursor-pointer text-gray-1000"
                    onClick={toggleDropdown}
                  >
                    <FontAwesomeIcon icon={faGlobe} className="fa-lg" />
                  </span>
                  {isDropdownOpen && (
                    <div
                      id="languageDropdown"
                      className="absolute mt-2 w-32 bg-white border rounded-md shadow-md right-0"
                    >
                      <button
                        className={`w-full text-left px-4 py-2 ${selectedLanguage === "en" ? "bg-gray-200" : ""}`}
                        onClick={() => changeLanguage("en")}
                      >
                        English
                      </button>
                      <button
                        className={`w-full text-left px-4 py-2 ${selectedLanguage === "al" ? "bg-gray-200" : ""}`}
                        onClick={() => changeLanguage("al")}
                      >
                        Albanian
                      </button>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Index;