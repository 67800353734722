import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTable,
  faListUl,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { NavLink } from "react-router-dom";
import SubMenu from "./SubMenu";
import { useTranslation } from "react-i18next";

function MenuList({ ...props }) {

  const { t } = useTranslation();
  const initMenus = [
    // {
    //   label: t('menusPage.header'), 
    // }, 
    {
      label: t('menusDataHeader.deals'),
      path: '/deals',
      icon: faTable,
    },
    {
      label: t('menusDataHeader.payments'),
      path: '/payment',
      icon: faMoneyCheckDollar,
    },
    {
      label: t('menusDataHeader.customers'),
      path: '/customers',
      icon: faListUl,
    },
  ];

  return (
    <div className="navWrapper p-4">
      <ul id="menu" className="mt-16">
        {initMenus?.map((menu) =>
          menu.submenu ? (
            <SubMenu key={menu.label} menu={menu} props={props} />
          ) : menu.path ? (
            <li key={menu.label} className={``} onClick={props.toggle}>
              <NavLink to={`${menu.path}`} className="link">
                {menu.icon && <FontAwesomeIcon icon={menu.icon} />}
                {menu.label}
              </NavLink>
            </li>
          ) : (
            <li key={menu.label} className="mt-5 mb-3">
              <span className="text-gray-500 font-medium uppercase text-xs mx-2">
                {menu.label} {menu.role}
              </span>
            </li>
          )
        )}
      </ul>
    </div>
  );
}

export default MenuList;