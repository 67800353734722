import Datatables from "../components/Datatables/Table";
import TableCell from "../components/Datatables/TableCell";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { formatNumber } from "../utils/formatNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../utils/formatDate";
import { formatDateWithoutTime } from "../utils/formatDateWithoutTime";
import EmptyTable from "../components/Empty/EmptyTable";
import { paymentStatus } from "../utils/paymentStatus";
import { paymentMethod } from "../utils/paymentMethod";
import { subscriptionStatus } from "../utils/subscriptionStatus";

function UserDeals({ loading, dataHeader, data, dealId, searchQuery }) {

  const filteredData = useMemo(() => {
    let result = dealId ? data.filter((deal) => deal.dealData['Row ID'] === dealId) : data;



    if (searchQuery) {
      result = result.filter((deal) =>
        deal.dealData.Description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }


    return result;
  }, [data, dealId, searchQuery]);


  return (
    <div>
      <Datatables loading={loading} dataHeader={dataHeader}>
        {filteredData.length === 0 && !loading ?
          (
            <tr>
              <td colSpan={dataHeader.length} className="text-center text-gray-700">
                <EmptyTable />
              </td>
            </tr>
          )

          : filteredData.map((row, index) => (
            <tr
              key={index}
              className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
            >
              <TableCell dataLabel="Description" showLabel={true}>
                <p className="font-normal text-sm text-black-900">{row.dealData.Description}</p>
              </TableCell>
              <TableCell dataLabel="Value" showLabel={true}>
                <p className="font-normal text-sm text-gray-500">{formatNumber(row.dealData.Value)} LEK</p>
              </TableCell>
              <TableCell dataLabel="Start Date" showLabel={true}>
                <p className="font-normal text-sm text-gray-500">{formatDateWithoutTime(row.dealData['Start date']) || ''}</p>
              </TableCell>
              <TableCell dataLabel="Months" showLabel={true}>
                <p className="font-normal text-sm text-gray-500">{row.dealData.Months}</p>
              </TableCell>
              <TableCell dataLabel="Payment" showLabel={true}>
                <p className="font-normal text-sm text-gray-500">{paymentStatus(row.dealData['Payment status'])}</p>
              </TableCell>
              <TableCell dataLabel="Payment Date" showLabel={true}>
                <p className="font-normal text-sm text-gray-500">{formatDate(row.dealData['Payment Date']) || ""}</p>
              </TableCell>
              <TableCell dataLabel="Payment method" showLabel={true}>
                <p className="font-normal text-sm text-gray-500">{paymentMethod(row.dealData['Payment method'])}</p>
              </TableCell>
              <TableCell dataLabel="Rate" showLabel={true}>
                <p className="font-normal text-sm text-gray-500"> {row.actorData.Rate}</p>
              </TableCell>
              <TableCell dataLabel="Commision" showLabel={true}>
                <p className="font-normal text-sm text-gray-500">{formatNumber(row.actorData.Commission)}</p>
              </TableCell>
              <TableCell dataLabel="Expire Date" showLabel={true}>
                <p className="font-normal text-sm text-gray-500">{formatDateWithoutTime(row.dealData['Expire date']) || ''}</p>
              </TableCell>
              <TableCell dataLabel="Subscription" showLabel={true}>
                <p className="font-normal text-sm text-gray-500">{subscriptionStatus(row.dealData.Subscription) || ''}</p>
              </TableCell>
              <TableCell dataLabel="Items" showLabel={true}>
                <div className="mt-2 flex flex-row gap-2 justify-end md:justify-center">
                  <Link
                    to={`/${row.dealData['Row ID']}/ItemsTable`}
                    className="bg-emerald-200 text-black-100 px-2 py-1 rounded-lg shadow-lg text-xs"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Link>
                </div>
              </TableCell>
            </tr>
          ))}
      </Datatables>
    </div>
  );


}
export default UserDeals;
