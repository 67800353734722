import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../pages/auth/Firebase";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from "firebase/auth";


const AuthContext = createContext();

export function useAuthContext(){
    return useContext(AuthContext)
}

export function AuthContextProvider({ children }) {
    const [currentUser,setCurrentUser]= useState()
    const [loading,setLoading] = useState(true)
    async function signup(email,password){
        
        const userDat = await   createUserWithEmailAndPassword(auth,email,password);
        console.log(userDat.user.uid)
    }
     
    function login(email,password){
        return signInWithEmailAndPassword(auth,email,password);
    }

    function logout(){
     signOut(auth)
    }
    function resetPass(email){
        return sendPasswordResetEmail(auth,email);
    }
    function getCurrentUserId() {
        return currentUser?.uid || null;
      }
    useEffect(()=>{
        const unsubscribe = auth.onAuthStateChanged((user)=>{
            setLoading(false)
            setCurrentUser(user)
        })

        return unsubscribe;
    },[])
 
   return (
       <AuthContext.Provider value={{ 
        currentUser,
        signup,
        login,
        logout,
        resetPass,
        getCurrentUserId

       }}>
           {!loading && children}
       </AuthContext.Provider>
   );
}
