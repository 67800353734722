import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Index";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../context/authContext";
import { useTranslation } from 'react-i18next';
import UserDeals from "./UserDeals";
import { getDealsData } from "../api/deals";
import ExportButton from "../utils/ExportToExcel";
import { paymentStatus } from "../utils/paymentStatus";
import { formatDate } from "../utils/formatDate";
import { formatDateWithoutTime } from "../utils/formatDateWithoutTime";
import { paymentMethod } from "../utils/paymentMethod";
import { subscriptionStatus } from "../utils/subscriptionStatus";
import { formatNumber } from "../utils/formatNumber";

function Deals() {
  const [loading, setLoading] = useState(true);
  const [sidebarToggle] = useOutletContext();
  const params = useParams();
  const [search] = useSearchParams();
  const tab = search.get("tab") || "notPaid"
  const { getCurrentUserId } = useAuthContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState(tab);
  const [data, setData] = useState({
    paid: [],
    notPaid: [],
    aktiv: [],
    joAktiv: []
  });
  const [counts, setCounts] = useState({
    paid: 0,
    notPaid: 0,
    aktiv: 0,
    joAktiv: 0
  });

  const { t } = useTranslation();

  const currentUserId = getCurrentUserId();

  const getApiData = async (currentUserId, tab) => {
    setLoading(true);
    try {
      const response = await getDealsData(currentUserId, tab)
      setData(prevData => {
        const updatedData = { ...prevData, [tab]: response };
        updateCounts(updatedData);
        return updatedData;
      });
    } catch (error) {
      console.error('Error fetching deals data:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateCounts = (updatedData) => {
    const newCounts = {
      paid: updatedData.paid.filter(deal => deal.dealData['Payment status'] === 'PAID').length,
      notPaid: updatedData.notPaid.filter(deal => deal.dealData['Payment status'] !== 'PAID').length,
      aktiv: updatedData.aktiv.filter(deal => deal.dealData.Subscription === 'Aktiv').length,
      joAktiv: updatedData.joAktiv.filter(deal => deal.dealData.Subscription !== 'Aktiv').length
    };
    setCounts(newCounts);
  };

  useEffect(() => {
    if (!currentUserId) return;
    if (data[selectedTab].length === 0) {
      getApiData(currentUserId, selectedTab);
    }
  }, [currentUserId, selectedTab]);

  const pageTitle = () => {
    if (!params.id) return t("dealsPage.header");
    return t("paidDealsPage.header");
  };

  const dataHeader = [
    {
      key: "description",
      label: t("dealsDataHeader.description"),
      exportKey: "dealData.Description",
    },
    {
      key: "value",
      label: t("dealsDataHeader.value"),
      exportKey: "dealData.Value",
      translator: formatNumber
    },
    {
      key: "startDate",
      label: t("dealsDataHeader.startDate"),
      exportKey: "dealData.Start date",
      translator: formatDateWithoutTime
    },
    {
      key: "months",
      label: t("dealsDataHeader.months"),
      exportKey: "dealData.Months"
    },
    {
      key: "payment",
      label: t("dealsDataHeader.payment"),
      exportKey: "dealData.Payment status",
      translator: paymentStatus
    },
    {
      key: "paymentDate",
      label: t("dealsDataHeader.paymentDate"),
      exportKey: "dealData.Payment Date",
      translator: formatDate
    },
    {
      key: "paymentMethod",
      label: t("dealsDataHeader.paymentMethod"),
      exportKey: "dealData.Payment method",
      translator: paymentMethod
    },
    {
      key: "rate",
      label: t("dealsDataHeader.rate"),
      exportKey: "actorData.Rate"
    },
    {
      key: "commision",
      label: t("dealsDataHeader.commision"),
      exportKey: "actorData.Commission",
      translator: formatNumber
    },
    {
      key: "expireDate",
      label: t("dealsDataHeader.expireDate"),
      exportKey: "dealData.Expire date",
      translator: formatDateWithoutTime
    },
    {
      key: "subscription",
      label: t("dealsDataHeader.subscription"),
      exportKey: "dealData.Subscription",
      translator: subscriptionStatus
    },
    {
      key: "items",
      label: t("dealsDataHeader.items"),
    },
  ];

  const filteredData = data[selectedTab];
  const exportDataHeader = dataHeader.filter(column => column.key !== "items");

  return (
    <>
      <main className="h-full mt-11">
        <Navbar toggle={sidebarToggle} />

        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <div className="mb-3 flex flex-col sm:flex-row justify-between sm:items-center gap-2">
              <p className="font-bold text-xl text-black-700 px-6 bg-white-200 rounded-md">{pageTitle()}</p>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t("dealsPage.searchPlaceholder")}
                className="px-3 py-2 border rounded-md w-full sm:max-w-[15rem]"
              />
            </div>

            <div className="mb-3 tabs-scroll flex">
              <button
                className={`px-4 py-2 mr-2 rounded-md ${selectedTab === "notPaid" ? "bg-emerald-200" : "bg-gray-200 text-black"}`}
                onClick={() => setSelectedTab("notPaid")}
              >
                <span className="mr-1">{t("dealsPage.notPaid")}</span>
                {selectedTab === "notPaid" && <span>({counts.notPaid})</span>}
              </button>
              <button
                className={`px-4 py-2 rounded-md ${selectedTab === "paid" ? "bg-emerald-200" : "bg-gray-200 text-black"}`}
                onClick={() => setSelectedTab("paid")}
              >
                <span className="mr-1">{t("dealsPage.Paid")}</span>
                {selectedTab === "paid" && <span>({counts.paid})</span>}
              </button>

              <button
                className={`px-4 py-2 mr-2 ml-2 rounded-md ${selectedTab === "aktiv" ? "bg-emerald-200" : "bg-gray-200 text-black"}`}
                onClick={() => setSelectedTab("aktiv")}
              >
                <span className="mr-1">{t("dealsPage.Aktiv")}</span>
                {selectedTab === "aktiv" && <span>({counts.aktiv})</span>}
              </button>
              <button
                className={`px-4 py-2 rounded-md ${selectedTab === "joAktiv" ? "bg-emerald-200" : "bg-gray-200 text-black"}`}
                onClick={() => setSelectedTab("joAktiv")}
              >
                <span className="mr-1">{t("dealsPage.joAktiv")}</span>
                {selectedTab === "joAktiv" && <span>({counts.joAktiv})</span>}
              </button>

              <ExportButton table={exportDataHeader} data={filteredData} entityName="Kontratat" className="sm:hidden block ml-2" />
              <ExportButton table={exportDataHeader} data={filteredData} entityName="Kontratat" className="hidden sm:block ml-auto" />

            </div>

            <UserDeals
              dealId={params.id}
              loading={loading}
              dataHeader={dataHeader}
              data={filteredData}
              currentUserId={currentUserId}
              searchQuery={searchQuery}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default Deals;