import { getUserToken } from "../pages/auth/AuthToken"

// const API_PATH = '/api'
const backendUrl = process.env.REACT_APP_BACKEND_URL

export const getDealsTableData = (id, customerId) => {
    return new Promise(async (resolve, reject) => {
        const res = await fetch(`${backendUrl}/${id}/${customerId}/customerDeal`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': `${await getUserToken()}`
            }
        })

        if (res.ok) return resolve(res.json())

        return reject(await res.json())
    })
}