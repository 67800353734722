import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useOutletContext } from "react-router-dom";
// import Dashboard from "./pages/Dashboard";
import AuthLayout from "./components/Layout/AuthLayout";
import GuestLayout from "./components/Layout/GuestLayout";
import Login from "./pages/auth/Login";
import Deals from "./pages/Deals";
import Payment from "./pages/Payment";
import Customers from "./pages/Customers";
import DealsTable from "./pages/DealsTable";
import ItemsTable from "./pages/ItemsTable";
import { DealsContextProvider } from "./context/dealsContext";
import { AuthContextProvider, useAuthContext } from "./context/authContext";
import ResetPasswordIndex from "./pages/auth/ResetPassword";
import { ApplicationContextProvider } from "./context/ApplicationContext";
import Navbar from "./components/Navbar/Index";

function App() {
  return (
    <ApplicationContextProvider>
      <AuthContextProvider>
        <DealsContextProvider>
          <Routes>

            <Route path="/" element={<AuthLayout />}>
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              <Route index element={<CheckAuthRedirect />} />

              <Route path="deals" element={<Deals />} />
              <Route path="deals/:id" element={<Deals />} />
              <Route path="customers" element={<Customers />} />
              <Route path=":id/dealsTable" element={<DealsTable />} />
              <Route path=":id/itemsTable" element={<ItemsTable />} />
              <Route path="payment" element={<Payment />} />
            </Route>

            <Route path="/auth/*" element={<GuestLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="resetPassword" element={<ResetPasswordIndex />} />
            </Route>
          </Routes>
        </DealsContextProvider>
      </AuthContextProvider>
    </ApplicationContextProvider>
  );
}

function CheckAuthRedirect() {
  const [sidebarToggle] = useOutletContext();
  const { currentUser } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth/login');
    }
    else {
      navigate('/deals');
    }
  }, [currentUser, navigate]);

  return <Navbar toggle={sidebarToggle} />
}

export default App;
