import { createContext, useContext, useState } from "react";


const DealsContext = createContext();

export function DealsContextProvider({ children }) {
   const [dealId, setDealId] = useState('');

  
   return (
       <DealsContext.Provider value={{ 
        dealId,
        setDealId
       }}>
           {children}
       </DealsContext.Provider>
   );
}


export function useDealContext() {
   return useContext(DealsContext);
}