import { createContext, useContext, useReducer } from "react"

const INITIAL_STATE={
    loading: false,
    sidebarToggle: false,
}

export const ApplicationContext = createContext(INITIAL_STATE);
export const useAppContext=()=>{
    return useContext(ApplicationContext)
}
const ApplicationReducer = (state, action) => {
    switch (action.type) {
      case "TOGGLE_SIDEBAR":
        return {
          ...state,
          sidebarToggle: !state.sidebarToggle,
        };
      default:
        return state;
    }
  };
export const ApplicationContextProvider =({children})=>{
    const [state,dispatch] = useReducer(ApplicationReducer,INITIAL_STATE);


return (
    <ApplicationContext.Provider value={{
        loading:state.loading,
        sidebarToggle: state.sidebarToggle,
        dispatch,
    }}>
     {children}
    </ApplicationContext.Provider>
)
}
