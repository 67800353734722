import React from "react";
import { utils, writeFile } from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
const {
  book_append_sheet,
  book_new,
  json_to_sheet,
} = utils;

function generateExcelFile({ columns, data, arrMapperKey, entityName }) {
  const toBeMapped = (Array.isArray(data) ? data : data?.[arrMapperKey]) || []
  const transformedData = toBeMapped.map((row) => {
    const rowData = {};
    columns.forEach((col) => {
      const {
        label,
        exportKey,
        translator = (val) => val,
        valueFinder
      } = col

      const [firstKey, nestedKey] = (exportKey || "").split(".")
      const value = valueFinder?.({ col, row, data }) || row?.[firstKey]?.[nestedKey] || row?.[firstKey] || ""

      const transValue = translator(value)
      rowData[label] = transValue
    });
    return rowData;
  });

  const sheet = json_to_sheet(transformedData);
  const workbook = book_new();
  book_append_sheet(workbook, sheet, "Sheet1");
  writeFile(workbook, `${entityName}.xlsx`);
}

export default function ExportButton({ table, data, entityName, arrMapperKey, className = "", }) {
  const handleExport = () => {
    generateExcelFile({
      columns: table,
      data,
      entityName,
      arrMapperKey
    });
  };

  return (
    <button
      onClick={handleExport}
      className={`bg-emerald-200 text-black px-4 py-2 rounded-md ${className}`}
    >
      <FontAwesomeIcon className="mr-2" icon={faFileExcel} />
      Export
    </button>
  );
}

