import React from "react";
import Navbar from "../components/Navbar/Index";
import { useLocation, useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuthContext } from "../context/authContext";
import { useTranslation } from 'react-i18next';
import UserTableDeals from "./UserTableDeals";
import { getCustomerName } from "../api/customers";
import { getDealsTableData } from "../api/dealsTable";

function DealsTable() {
  const [sidebarToggle] = useOutletContext();

  const { t } = useTranslation();


  const [loading] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const [customerId, setCustomerId] = useState()
  const { getCurrentUserId } = useAuthContext();
  const currentUserId = getCurrentUserId()

  useEffect(() => {
    if (!pathname) return
    setCustomerId(pathname.split('/')[1])
  }, [pathname])
  //fetch
  const defaultValue = []
  const [data, setData] = useState(defaultValue);
  const [customerName, setCustomerName] = useState(defaultValue);

  const getApiData = async (currentUserId, customerId) => {
    getDealsTableData(currentUserId, customerId).then(response => setData(response))
  };

  useEffect(() => {
    if (!currentUserId || !customerId) return
    getApiData(currentUserId, customerId);
  }, [currentUserId, customerId]);
  //Customer Name
  const getApiCustomerName = async (customerId) => {
    getCustomerName(customerId).then(response => setCustomerName(response))
  };

  useEffect(() => {
    if (!customerId) return
    getApiCustomerName(customerId);
  }, [customerId]);

  const dataHeader = [
    {
      key: "description",
      label: t("dealsDataHeader.description"),
    },

    {
      key: "startDate",
      label: t("dealsDataHeader.startDate"),
    },
    {
      key: "months",
      label: t("dealsDataHeader.months"),
    },
    {
      key: "value",
      label: t("dealsDataHeader.value"),
    },
    {
      key: "paymentStatus",
      label: t("dealsDataHeader.paymentStatus"),
    },
  ];
  return (
    <>
      <main className="h-full mt-11">
        <Navbar toggle={sidebarToggle} />

        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <p className="font-bold text-xl text-black-700 py-4 px-6 bg-white-200 rounded-md">{customerName} -{t("dealsPage.header")}</p>
            <UserTableDeals
              loading={loading}
              dataHeader={dataHeader}
              data={data}
              currentUserId={currentUserId}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default DealsTable