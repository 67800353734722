import React from "react";
import { useTranslation } from "react-i18next";

function TableCell({ dataLabel, className, children, showLabel }) {
  const { t } = useTranslation();

  const translation = t(dataLabel)

  return (
    <>
      <td
        data-label={translation}
        className={
          `${showLabel &&
          "before:float-left before:text-sm before:font-bold before:content-[attr(data-label)] before:md:content-none text-right"
          } border-b md:text-left block md:table-cell md:whitespace-nowrap text-slate-800 md:first:pl-4 md:last:pr-4 px-3 py-2 ` +
          className
        }
      >
        <div className="min-h-[20px]">
        {children}
        </div>
      </td>
    </>
  );
}

export default TableCell;
