import { Link } from "react-router-dom";
import Datatables from "../components/Datatables/Table";
import TableCell from "../components/Datatables/TableCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
function UserCustomer({ loading, dataHeader, customerData }) {

    return (
        <Datatables loading={loading} dataHeader={dataHeader}>
            {customerData?.map((row, index) => (
                <tr
                    key={index}
                    className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
                >
                    <TableCell dataLabel="Name" showLabel={true}>
                        <span className="font-medium text-sm text-gray-900">
                            {row.Name}
                        </span>
                    </TableCell>
                    <TableCell dataLabel="TIN" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{row.TIN}</p>
                    </TableCell>
                    <TableCell dataLabel="Adress" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{row.Address}</p>
                    </TableCell>
                    <TableCell dataLabel="City" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{row.City}</p>
                    </TableCell>
                    <TableCell dataLabel="Phone" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500" >{row.Phone}</p>
                    </TableCell>
                    <TableCell dataLabel="Alias" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{row.Alias}</p>
                    </TableCell>
                    <TableCell dataLabel="Related Deals" showLabel={true}>
                        <div className="mt-2 flex flex-row gap-2 justify-end md:justify-center">
                            <Link
                                to={`/${row['Row ID']}/DealsTable`}
                                className="bg-emerald-200 text-black-100 px-2 py-1 rounded-lg shadow-lg text-xs "
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Link>
                        </div>
                    </TableCell>
                </tr>

            ))}
        </Datatables>
    );
}

export default UserCustomer;
