import React from "react";
import Navbar from "../components/Navbar/Index";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuthContext } from "../context/authContext";
import { useTranslation } from 'react-i18next';
import UserCustomer from "./UserCustomer";
import { getCustomerData, getCustomerDeals } from "../api/customers";

function Customers() {
  const [sidebarToggle] = useOutletContext();
  const { t } = useTranslation();

  const [loading] = useState(false);

  //fetch
  const defaultValue = []
  const [data, setData] = useState(defaultValue);
  const [customerData, setCustomerData] = useState(defaultValue);
  const { getCurrentUserId } = useAuthContext();
  const [searchQuery, setSearchQuery] = useState("");
  const currentUserId = getCurrentUserId();

  const getApiData = async (currentUserId) => {
    getCustomerDeals(currentUserId).then(response => setData(response));
  };

  useEffect(() => {
    if (!currentUserId) return;
    getApiData(currentUserId);
  }, [currentUserId]);

  // customers
  const getApiCustomerData = async (currentUserId) => {
    getCustomerData(currentUserId).then(response => setCustomerData(response));
  };

  useEffect(() => {
    if (!currentUserId) return;
    getApiCustomerData(currentUserId);
  }, [currentUserId]);

  const dataHeader = [
    {
      key: 'name',
      label: t("customersDataHeader.name"),
    },
    {
      key: 'tin',
      label: t('customersDataHeader.tin'),
    },
    {
      key: 'adress',
      label: t('customersDataHeader.adress'),
    },
    {
      key: 'city',
      label: t('customersDataHeader.city'),
    },
    {
      key: 'phone',
      label: t('customersDataHeader.phone'),
    },
    {
      key: 'alias',
      label: t('customersDataHeader.alias'),
    },
    {
      key: 'deals',
      label: t('customersDataHeader.deals'),
    },
  ];

  const filteredCustomerData = customerData.filter(customer =>
    customer.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.TIN.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <main className="h-full mt-11">
        <Navbar toggle={sidebarToggle} />

        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <div className="mb-4 flex flex-col sm:flex-row justify-between sm:items-center gap-2">
              <p className="font-bold text-xl text-black-700 px-6 bg-white-200 rounded-md">{t("menusDataHeader.customers")}</p>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t("menusDataHeader.searchPlaceholder")}
                className="px-3 py-2 border rounded-md w-full sm:max-w-[15rem]"
              />
            </div>
            <UserCustomer
              currentUserId={currentUserId}
              loading={loading}
              dataHeader={dataHeader}
              data={data}
              customerData={filteredCustomerData}
              searchQuery={searchQuery}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default Customers;
