import React from "react";
import Datatables from "../components/Datatables/Table";
import TableCell from "../components/Datatables/TableCell";
import { Link } from "react-router-dom";
import { formatNumber } from "../utils/formatNumber";
import EmptyTable from "../components/Empty/EmptyTable";
import { useTranslation } from 'react-i18next';
import { formatDate } from "../utils/formatDate";
import { formatDateWithoutTime } from "../utils/formatDateWithoutTime";

function UserPayment({ loading, dataHeader, data, activeTab, searchQuery }) {
  const userPaidDealsData = [];
  const userNotPaidDealsData = [];
  const userPayableDealsData = [];

  const { t } = useTranslation();

  (data.dealActor || []).forEach((userDealData) => {
    const deal = data.deals ? data.deals.find((deal) => deal['Row ID'] === userDealData['Deal ID']) : null;
    if (deal) {
      switch (userDealData['Payment status']) {
        case 'PAID':
          userPaidDealsData.push({
            ...userDealData,
            currentUserPaidDeal: true,
            deal,
          });
          break;
        case 'NOT PAID':
          userNotPaidDealsData.push({
            ...userDealData,
            currentUserNotPaidDeal: true,
            deal,
          });
          break;
        case 'PAYABLE':
          userPayableDealsData.push({
            ...userDealData,
            currentUserPayableDeal: true,
            deal,
          });
          break;
        default:
          console.warn('Unknown payment status:', userDealData['Payment status']);
      }
    }
  });

  let datatoUse;
  if (activeTab === 'tab1') datatoUse = userPaidDealsData;
  if (activeTab === 'tab2') datatoUse = userNotPaidDealsData;
  if (activeTab === 'tab3') datatoUse = userPayableDealsData;

  const filteredData = datatoUse.filter(row =>
    row.deal && row.deal.Description
      ? row.deal.Description.toLowerCase().includes(searchQuery.toLowerCase())
      : false
  );

  const totalValue = filteredData.reduce((sum, row) => {
    const value = row.deal && row.deal.Value
      ? typeof row.deal.Value === 'string'
        ? parseFloat(row.deal.Value.replace(/,/g, '')) || 0
        : row.deal.Value
      : 0;
    return sum + value;
  }, 0);


  const totalCommission = filteredData.reduce((sum, row) => {
    const commission = row && row['Commission']
      ? typeof row['Commission'] === 'string'
        ? parseFloat(row['Commission'].replace(/,/g, '')) || 0
        : row['Commission']
      : 0;
    return sum + commission;
  }, 0);


  return (
    <div className="">
      <Datatables loading={loading} dataHeader={dataHeader}>
        {(filteredData.length === 0 && !loading) ? (
          <tr>
            <td colSpan={dataHeader.length} className="text-center text-gray-700">
              <EmptyTable />
            </td>
          </tr>
        ) : (
          <>
            {filteredData.map((row, index) => (
              <tr
                key={index}
                className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
              >
                <TableCell dataLabel="Title" showLabel={true}>
                  <div className="mt-1 flex flex-row gap-2 px-3 py-1 justify-end md:justify-start">
                    <Link
                      to={`/deals/${row.deal['Row ID']}?tab=${row.deal['Payment status'] === 'PAID' ? 'paid' : 'notPaid'}`}
                      className="bg-emerald-200 text-black-100 px-4 py-2 rounded-lg shadow-lg text-xs"
                    >
                      {row.deal.Description}
                    </Link>
                  </div>
                </TableCell>
                <TableCell dataLabel="Value" showLabel={true}>
                  <p className="font-normal text-sm text-gray-500">{row.deal && row.deal.Value ? formatNumber(row.deal.Value) : ''} LEK</p>
                </TableCell>
                <TableCell dataLabel="Start Date" showLabel={true}>
                  <p className="font-normal text-sm text-gray-500">{formatDateWithoutTime(row.deal && row.deal['Start date'] ? (row.deal['Start date']) : '')}</p>
                </TableCell>
                <TableCell dataLabel="Payment Date" showLabel={true}>
                  <p className="font-normal text-sm text-gray-500">{row['Payment Date'] ? formatDate(row['Payment Date']) : ''}</p>
                </TableCell>
                <TableCell dataLabel="Rate" showLabel={true}>
                  <p className="font-normal text-sm text-gray-500">{row['Rate']}</p>
                </TableCell>
                <TableCell dataLabel="Commission" showLabel={true}>
                  <p className="font-normal text-sm text-gray-500">{row['Commission'] ? `${formatNumber(row['Commission'])} LEK` : ''}</p>
                </TableCell>
              </tr>
            ))}
            <tr
              className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
            >
              <TableCell className={"hidden md:block"}>
                <p> </p>
              </TableCell>
              <TableCell>
                <div className="flex flex-col md:hidden">
                  <div className="flex items-center justify-between my-3 space-x-1">
                    <p className="font-bold text-gray-800">{t('totalValue.total')}</p>
                    <p className="font-bold text-gray-800">{formatNumber(totalValue)} LEK</p>
                  </div>
                  <div className="flex items-center justify-between space-x-1">
                    <p className="font-bold text-gray-800">{t('totalCommission.total')}</p>
                    <p className="font-bold text-gray-800">{formatNumber(totalCommission)} LEK</p>
                  </div>
                </div>
                <div className="hidden md:block">
                  <p className="font-bold text-gray-700">{formatNumber(totalValue)} LEK</p>
                </div>
              </TableCell>
              <TableCell className={"hidden md:block"}>
                <p> </p>
              </TableCell>
              <TableCell className={"hidden md:block"}>
                <p> </p>
              </TableCell>
              <TableCell className={"hidden md:block"}>
                <p> </p>
              </TableCell>
              <TableCell className="hidden md:block">
                <p className="font-bold text-gray-700">{formatNumber(totalCommission)} LEK</p>
              </TableCell>
            </tr>
          </>
        )}
      </Datatables>
    </div>
  );
}

export default UserPayment;



