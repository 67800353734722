import Datatables from "../components/Datatables/Table";
import TableCell from "../components/Datatables/TableCell";
import { useTranslation } from 'react-i18next';
import { formatNumber } from "../utils/formatNumber";
import { formatDateWithoutTime } from "../utils/formatDateWithoutTime";

function UserTableDeals({ loading, dataHeader, data, currentUserId }) {

    const { t } = useTranslation();
    const paymentStatus = (status) => {
        if (status === 'PAID') return t("dealsPage.Paid")
        return t("dealsPage.notPaid")
    }


    return (
        <Datatables loading={loading} dataHeader={dataHeader}>
            {data?.map((row, index) => (
                <tr
                    key={index}
                    className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
                >
                    <TableCell dataLabel="Description" showLabel={true}>
                        <span className="font-medium text-sm text-gray-900">
                            {row.Description}
                        </span>
                    </TableCell>
                    <TableCell dataLabel="Start Date" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{formatDateWithoutTime(row['Start date'])}</p>
                    </TableCell>
                    <TableCell dataLabel="Months" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{row.Months}</p>
                    </TableCell>
                    <TableCell dataLabel="Value" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500"> {formatNumber(row.Value)}</p>
                    </TableCell>
                    <TableCell dataLabel="Payment Status" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{paymentStatus(row['Payment status'])}</p>
                    </TableCell>
                </tr>

            ))}
        </Datatables>
    );
}

export default UserTableDeals;
