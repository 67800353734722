//import { Link } from "react-router-dom";
import Datatables from "../components/Datatables/Table";
import TableCell from "../components/Datatables/TableCell";

function UserItems({ loading, dataHeader, data, handleDelete }) {

    return (
        <Datatables loading={loading} dataHeader={dataHeader}>
            {data?.map((row, index) => (
                <tr
                    key={index}
                    className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
                >
                    <TableCell dataLabel="Name" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{row.itemsData?.[0]?.Name}</p>
                    </TableCell>
                    <TableCell dataLabel="Quantity" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{row.dealItemData.Quantity}</p>
                    </TableCell>
                    <TableCell dataLabel="Value" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500">{row.dealItemData.Value} LEK</p>
                    </TableCell>
                    <TableCell dataLabel="Note" showLabel={true}>
                        <p className="font-normal text-sm text-gray-500" >{row.dealItemData.Notes}</p>
                    </TableCell>
                </tr>

            ))}
        </Datatables>
    );
}

export default UserItems;
