import firebaseApp from './Firebase'
import { getAuth, getIdToken } from 'firebase/auth';


export const auth = getAuth(firebaseApp);

const getUserToken = () => {
    return new Promise(async (resolve, reject) => {
        if (auth.currentUser) {
            try {
                return resolve(await getIdToken(auth.currentUser))
            } catch (err) {
                console.error(err.message)
                return reject()
            }
        }
    });
}

export { getUserToken }