
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/authContext";
import { useTranslation } from 'react-i18next';


function LoginIndex() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useAuthContext();
  const { t } = useTranslation();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setMessage('');
      setError('');

      await login(email, password);
      navigate("/deals");
    } catch (error) {
      setLoading(false);
      const errorCode = error.code || error;
      console.log(error)
      switch (errorCode) {
        case "auth/user-not-found":
          setError(t('login.ErrorUser'));
          break;
        case "auth/user-disabled":
          setError("This user is disabled");
          break;
        case "auth/wrong-password":
          setError(t('login.ErrorPass'));
          break;
        case "auth/invalid-email":
          setError(t('login.ErrorEmail'));
          break;
        case "auth/invalid-login-credentials":
          setError(t('login.ErrorCredentials'));
          break;
        default:
          setError(t.errorTryingToLogin);
      }

    }
  };



  // const LoginImage =
  //   // "https://icons.veryicon.com/png/o/miscellaneous/color-work-icon/partnership-4.png";
  //   "https://edp.raincode.my.id/static/media/login.cc0578413db10119a7ff.png";
  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex w-full justify-center ">
          <div className="hidden md:bg-emerald-500 md:min-h-screen md:flex flex-wrap md:w-1/2">
            <div className="items-center text-center flex flex-col relative justify-center mx-auto">
              {/* <img
                src={LoginImage}
                alt="Logo Login"
                className="md:w-72 w-48 mx-auto"
              /> */}
              <div className="md:block hidden text-slate-100">
                <h1 className="font-bold text-4xl pb-2">
                  {t('loginDataHeader.imageInfo')}
                </h1>
              </div>
            </div>
          </div>
          {/* Login Section */}
          <div className="flex flex-col flex-1 items-center justify-center">
            <div className="loginWrapper max-w-xs flex flex-col w-full text-gray-800">

              <div className="mb-2 font-semibold self-center text-2xl text-gray-800">
                {t('loginPage.header')}
              </div>

              {/* Sparator */}

              <span className="bg-white px-4 text-center text-xs text-emerald-500 uppercase max-w-xs">
                {t('loginDataHeader.loginInfo')}
              </span>

              {/* Login Form */}
              <div className="p-4">
                {error && <div className="alert alert-light text-red-500" role="alert">
                  {error}
                </div>}
                {message && <div className="alert alert-light text-emerald-500" role="alert">
                  {message}
                </div>}
                <form onSubmit={handleSubmit}>
                  {/* Username */}
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>

                      <input
                        id="email"
                        type="text"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder={t('loginDataHeader.emailPlaceHolder')}
                      />
                    </div>
                    {error?.email && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {error.email[0]}
                      </span>
                    )}
                  </div>

                  {/* Password */}
                  <div className="flex flex-col mb-2">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faLock} />
                      </div>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder={t('loginDataHeader.passwordPlaceHolder')}
                      />
                    </div>
                    {error?.password && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {error.password[0]}
                      </span>
                    )}
                  </div>

                  {/* Forgot Password Link */}
                  <div className="flex items-center mb-2 -mt-2 md:-mobile-margin">
                    <div className="flex ml-auto">
                      <Link
                        to="/auth/resetPassword"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        // }}
                        className="inline-flex font-semibold text-xs sm:text-sm text-emerald-500 hover:text-emerald-700"
                      >
                        {t('loginDataHeader.forgotPassword')}
                      </Link>
                    </div>
                  </div>

                  {/* Button Login */}
                  <div className="flex w-full">
                    <button
                      disabled={loading}
                      type="submit"
                      className="flex items-center justify-center focus:outline-none text-white text-sm bg-emerald-500 hover:bg-emerald-700 rounded-lg md:rounded md:py-2 py-3 w-full transition duration-150 ease-in"
                    >
                      <span className="mr-2 md:uppercase">
                        {loading ? "Processing...." : t('loginDataHeader.login')}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
              {/* 
              
              <div className="relative mt-6 h-px bg-gray-300">
                <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
                  <span className="bg-white px-4 text-xs text-gray-500 uppercase">
                    OR
                  </span>
                </div>
              </div>

              
              <div className="flex justify-between w-full mt-6">
                <button
                  disabled={loading}
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-slate-500 text-sm bg-slate-200 rounded-lg md:rounded md:py-2 px-3 py-3 w-full transition duration-150 ease-in"
                >
                  <FontAwesomeIcon icon={faGoogle} />
                  <span className="mr-2 flex-1">Login with Google</span>
                </button>
              </div>
              <div className="flex justify-between w-full mt-2">
                <button
                  disabled={loading}
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-slate-500 text-sm bg-slate-200 rounded-lg md:rounded md:py-2 px-3 py-3 w-full transition duration-150 ease-in"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                  <span className="mr-2 flex-1">Login with Facebook</span>
                </button>
              </div> 

                    */}

              {/* <div className="flex justify-center items-center  my-6 md:mb-0">
                <Link
                  to="/auth/register"
                  className="inline-flex items-center font-bold text-emerald-500 hover:text-emerald-700 text-xs text-center"
                >
                  <span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
                    </svg>
                  </span>
                  <span className="ml-2"> {t('loginDataHeader.Register')}</span>
                </Link>
              </div> */}
              {/* End Register Link */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginIndex;
